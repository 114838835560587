// in src/App.js
import React from 'react';
import UserIcon from '@material-ui/icons/People';
import ErrorIcon from '@material-ui/icons/Error';
import { Admin, Resource } from 'react-admin';
import { AccountList, AccountsEdit, AccountShow, AccountsCreate } from './accounts';
import { UserList, UserEdit, UserShow, UserCreate } from './users';
import { ErrorList } from './errors';
import { IdTypesList, IdTypesEdit } from './idtypes';
import { SubscriptionsCreate, SubscriptionsList } from './subscriptions';
import { UserPermissionsCreate } from './users_permissions';
import { AccountTypeList, AccountTypeShow } from './accounttypes';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import MyLayout from './MyLayout';
import Login from './Login';

const CustomLoginPage = props => (
  <Login backgroundImage={'background.jpg'}
    {...props}
  />
);

const App = () => (
  <Admin title="AgregasGUI"
    loginPage={CustomLoginPage}
    appLayout={MyLayout}
    dataProvider={dataProvider}
    authProvider={authProvider}>
    {permissions => [
      <Resource name="accounts" list={AccountList} edit={AccountsEdit}
        create={AccountsCreate} show={AccountShow} icon={UserIcon} options={{ label: 'Cuentas' }} />,
      <Resource name="users_permissions_status" create={UserPermissionsCreate} />,
      <Resource name="idtypes" options={{ label: 'Tipos de Doc.' }} list={IdTypesList}
        edit={permissions === 'true' ? IdTypesEdit : null} />,
      <Resource name="users_permissions" />,
      <Resource name="accounttypes" list={permissions === 'true' ? AccountTypeList : null}
        show={permissions === 'true' ? AccountTypeShow : null} options={{ label: 'Tipos de cuenta' }} />,
      <Resource name="services" />,
      <Resource name="subscriptions" list={permissions === 'true' ? SubscriptionsList : null}
        create={permissions === 'true' ? SubscriptionsCreate : null}
        options={{ label: 'Suscripciones' }} />,
      <Resource name="subscriptions_state" />,
      <Resource name="services_permissions_status" />,
      <Resource name="services_permissions" />,
      permissions === 'true' ?
        <Resource name="users" options={{ label: 'Usuarios API' }} list={UserList}
          edit={UserEdit} show={UserShow} create={UserCreate} />
        : null,
      permissions === 'true' ?
        <Resource name="connectors/ldap/queue" options={{ label: 'Errores LDAP' }} icon={ErrorIcon} list={ErrorList} />
        : null,
      permissions === 'true' ?
        <Resource name="connectors/gsuite/queue" options={{ label: 'Errores Google' }} icon={ErrorIcon} list={ErrorList} />
        : null,
      permissions === 'true' ?
        <Resource name="connectors/active_directory/queue" options={{ label: 'Errores Office365' }} icon={ErrorIcon} list={ErrorList} />
        : null,
      permissions === 'true' ?
        <Resource name="connectors/mailman/queue" options={{ label: 'Errores Mailman' }} icon={ErrorIcon} list={ErrorList} />
        : null,
      permissions === 'true' ?
        <Resource name="connectors/openvpn/queue" options={{ label: 'Errores OpenVPN' }} icon={ErrorIcon} list={ErrorList} />
        : null,
    ]}
  </Admin>
);

export default App;
