import React, { Component } from 'react';
import {
    EditController,
    LongTextInput,
    SimpleForm,
    TextField,
    FormDataConsumer,
    RadioButtonGroupInput,
    required,
    Edit,
    ReferenceField,
    TextInput,
    DisabledInput,
    Toolbar,
    SaveButton,
    crudUpdate
} from 'react-admin';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import ActionsBar from './ActionsBar';

/*
const editStyle = theme => ({
    root: {
        paddingTop: 0,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
});

const SubscriptionEdit = ({ classes, onCancel, ...props }) => (
    <EditController {...props}>
        {controllerProps =>
            controllerProps.record ? (
                <div className={classes.root}>
                    <SimpleForm
                        className={classes.form}
                        basePath={controllerProps.basePath}
                        record={controllerProps.record}
                        save={controllerProps.save}
                        version={controllerProps.version}
                        redirect="list"
                        resource="subscriptions"
                        toolbar={<ActionsBar />}
                    >
                        <FormDataConsumer>
                          {({ formData, ...rest }) => formData.dynamic_fields.motive &&
                              <TextField label="Motivo" source="dynamic_fields.motive" {...rest} />
                          }
                        </FormDataConsumer>
                        <LongTextInput label="nota" source="note" rowsMax={15} helperText="Lo ve el usuario"/>
                    </SimpleForm>
                </div>
            ) : null
        }
    </EditController>
);

export default withStyles(editStyle)(SubscriptionEdit);
*/

// A custom action creator which modifies the values before calling the default crudCreate action creator
const saveWithTransition = (values, id, basePath, redirectTo, transition) =>
    crudUpdate('subscriptions', id, { ...values, transition: transition }, undefined, basePath, false);

class SaveWithTransitionButtonView extends Component {
    handleClick = () => {
        const { basePath, handleSubmit, redirect, saveWithTransition, transition } = this.props;
        console.log(this.props);

        return handleSubmit(values => {
            saveWithTransition(values, this.props.record.id, basePath, redirect, transition);
        });
    };

    render() {
        const { handleSubmitWithRedirect, saveWithTransition, transition,  ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                {...props}
            />
        );
    }
}

const SaveWithTransition = connect(
    undefined,
    { saveWithTransition }
)(SaveWithTransitionButtonView);

const validateRequired = required("Requerido");

export const SubscriptionEdit = props => (
    <Edit
        {...props}
        /* disable the app title change when shown */
        title=" "
        undoable={false}
    >
        <SimpleForm
            /* The form must have a name dependent on the record, because by default all forms have the same name */
            form={`post_edit_${props.id}`}
            toolbar={<SubscriptionEditToolbar />}
        >
            <ReferenceField linkType={false} source="service_id" reference="services" label="">
              <TextField source="description" />
            </ReferenceField>
            <TextField source="dynamic_fields.motive" label="Justificativo" />
            <LongTextInput label="Nota" source="note" rowsMax={15} helperText="Esto lo verá el usuario"/>
        </SimpleForm>
    </Edit>
);

const SubscriptionEditToolbar = props => (
    <Toolbar {...props} >
      {(props.record.state_id === 1 || props.record.state_id === 5) &&
          <SaveWithTransition
              label="Desactivar"
              redirect={false}
              submitOnEnter={false}
              variant="flat"
              transition="deactivate"
          />
      }

     {(props.record.state_id ===  2 || props.record.state_id ===  8) &&
         <SaveWithTransition
             label="Activar"
             redirect={false}
             submitOnEnter={false}
             variant="flat"
             transition="activate"
         />
      }

      {(props.record.state_id ===  3 || props.record.state_id ===  6) &&
        <SaveWithTransition
            label="Activar"
            redirect={false}
            submitOnEnter={false}
            variant="flat"
            transition="activate"
        />
       }

       {(props.record.state_id ===  3 || props.record.state_id ===  6) &&
         <SaveWithTransition
             label="Desaprobar"
             redirect={false}
             submitOnEnter={false}
             variant="flat"
             transition="deactivate"
         />
        }

      {(props.record.state_id ===  4) &&
        <SaveWithTransition
            label="Desactivar"
            redirect={false}
            submitOnEnter={false}
            variant="flat"
            transition="deactivate"
        />
       }

       {(props.record.state_id ===  4) &&
         <SaveWithTransition
             label="Dejar activo"
             redirect={false}
             submitOnEnter={false}
             variant="flat"
             transition="activate"
         />
        }
    </Toolbar>
);
