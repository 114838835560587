// in src/accounttypes.js
import React from 'react';
import { List, Show, Edit, Create, Datagrid, TextField, EmailField, DateField, Filter,
         TextInput, SimpleForm, TabbedShowLayout, Tab, ReferenceManyField, ReferenceField} from 'react-admin';
import AddPermissionButton from './add_permission_button';
import ListSwitch from './listSwitch';


const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

export const AccountTypeList = props => (
    <List {...props}
      bulkActionButtons={false}
      filters={<UserFilter />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" label="Tipo"/>
            <ListSwitch />
        </Datagrid>
    </List>
);

export const AccountTypeShow = props => (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Datos">
          <TextField source="id" />
          <DateField source="created" label="Creado" />
          <DateField source="last_updated" label="Modificado" />
          <TextField source="name" label="Tipo" />
        </Tab>
        <Tab label="Permisos" path="permissions">
          <ReferenceManyField
            addLabel={false}
            reference="services_permissions_status"
            target="account_type"
            sort={{ field: 'created', order: 'DESC' }}
            >
            <Datagrid>
              <DateField source="created" locales="es-ES" />
              <DateField source="last_updated" locales="es-ES" />
              <TextField source="explain" />
              <ListSwitch />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
);


export const ServicesPermissionsStatusList = props => (
    <List {...props}
      bulkActionButtons={false}
      filters={<UserFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="account_type_id" reference="accounttypes" label="Tipo de Cuenta">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="permission_id" reference="services_permissions" label="Permiso">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="service_id" reference="services" label="Service">
              <TextField source="name" />
            </ReferenceField>
            <ListSwitch />
        </Datagrid>
    </List>
);

export const UserCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" label="Username"/>
        <TextInput source="first_name" label="Name"/>
        <TextInput source="last_name" label="SN"/>
        <TextInput label="Password" source="password" type="password"/>
        <TextInput label="Password confirm" source="password_confirm" type="password"/>
      </SimpleForm>
    </Create>
);
