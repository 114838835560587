// in src/comments/listSwitch.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { switchActivate, switchDeactivate } from './listSwitchActions';


class ListSwitch extends Component {
    state = {
        checked: false,
    }

    componentDidMount() {
      const { record } = this.props;
      this.setState({ checked: record.is_active });
    }

    handleClick = event => {
        const { switchActivate, switchDeactivate, record, resource } = this.props;
        this.setState({ checked: event.target.checked });

        if (this.state.checked) {
          switchDeactivate(record.id, record, resource);
        } else {
          switchActivate(record.id, record, resource);
        }
    }

    render() {
        return <Switch checked={this.state.checked} onChange={this.handleClick} value="activo" />;
    }
}

ListSwitch.propTypes = {
    record: PropTypes.object,
    switchActivate: PropTypes.func.isRequired,
    switchDeactivate: PropTypes.func.isRequired,
};

export default connect(null, {
  switchActivate,
  switchDeactivate
})(ListSwitch);
