// in src/authProvider.js
import {
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
} from 'react-admin';
import { stringify } from 'query-string';

const api_uri = process.env.REACT_APP_SERVER_URL;
const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;

function isSuperuser(access_token) {
  const request = new Request(api_uri + '/api/v2/me/', {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Authorization': `Bearer ` + access_token
    }),
  })

  return request;
}

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const grant_type = 'password';
    const request = new Request(api_uri + '/o/token/', {
      method: 'POST',
      body: stringify({ grant_type, username, password }),
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(`${client_id}:${client_secret}`)}`
      }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        localStorage.setItem('access_token', access_token);
        return access_token;
      })
      .then(access_token =>
        fetch(isSuperuser(access_token))
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(response => {
            localStorage.setItem('is_superuser', response.results[0].is_superuser);
          })
      );
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('is_superuser');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('is_superuser');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const is_superuser = localStorage.getItem('is_superuser');
    return is_superuser ? Promise.resolve(is_superuser) : Promise.reject();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
  }

  return Promise.reject();
}
