import React from 'react';
import {
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SimpleForm,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  TextInput,
  SelectInput,
  BooleanInput,
  AutocompleteInput,
  FunctionField
} from 'react-admin';
import { parse } from 'query-string';
import Chip from '@material-ui/core/Chip';
import { SubscriptionEdit } from './SubscriptionEdit'
import rowStyle from './rowStyle';


const SubscriptionsFilter = props => (
    <Filter {...props}>
        <TextInput label="Buscar" source="search" alwaysOn />
        <BooleanInput label="En espera" source="state__awaiting_for_admin"/>
        <ReferenceInput label="Estado" source="state__id" reference="subscriptions_state" allowEmpty>
          <SelectInput optionText="description" />
        </ReferenceInput>
    </Filter>
);

export const SubscriptionsCreate = props => {
  const { account_id: account_id_string } = parse(props.location.search);
  const account_id = account_id_string ? parseInt(account_id_string, 10) : '';

  const redirect = account_id ? `/accounts/${account_id}/show/subscriptions` : false;
  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={{ account_id: account_id }}
        redirect={redirect}
      >
        <ReferenceArrayInput label="Servicios" source="service_id"
         reference="services" filter={{ is_active: true, available_to_subscribe: account_id }}>
          <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
        { account_id === '' &&
          <ReferenceInput label="Cuenta" source="accounts" reference="accounts">
            <AutocompleteInput optionText="user.username" />
          </ReferenceInput>
        }
      </SimpleForm>
    </Create>
  );
};

export const SubscriptionsList = ({ permissions, ...props}) => (
    <List {...props}
      bulkActionButtons={false}
      filters={<SubscriptionsFilter />}
      >
        <Datagrid rowStyle={rowStyle} rowClick="expand" expand={ permissions === 'true' ? <SubscriptionEdit/> : null }>
            <TextField source="id" />
            <ReferenceField linkType="show" source="account_id" reference="accounts" label="Cuenta">
              <TextField source="user.username" />
            </ReferenceField>
            <ReferenceField linkType={false} source="service_id" reference="services" label="Servicio">
              <TextField source="description" />
            </ReferenceField>
            <DateField source="created" label="Creado" locales="es-ES"/>
            <ReferenceField linkType={false} source="state_id" reference="subscriptions_state" label="">
              <FunctionField render={function render(record) {
                  if (record.codename === "awaiting_deactivation") {
                    return(<Chip
                              label="Activo: Esperando desactivación"
                              color="primary"
                            />);
                  }

                  if (record.codename === "awaiting_activation" || record.codename === "awaiting_initialization_approval") {
                    return(<Chip
                              label="Inactivo: Esperando activación"
                              color="error"
                            />);
                  }

                  switch(record.codename) {
                    case ("initialized"):
                      return(<Chip
                                label="Servicio activo"
                                color="primary"
                              />);
                    case ("activated"):
                      return(<Chip
                                label="Servicio activo"
                                color="primary"
                              />);
                    case "deactivated":
                      return(<Chip
                                label="Servicio inactivo"
                                color="error"
                              />);
                    case "rejected":
                      return(<Chip
                                label="Rechazado"
                                color="error"
                              />);
                    default:
                      return(<Chip
                                label="Servicio inactivo"
                                color="error"
                              />);
                  }
                }} />
            </ReferenceField>
        </Datagrid>
    </List>
);
