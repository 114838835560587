// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { subscriptionActivate, subscriptionDeactivate } from './subscriptionsActions';
import MuiToolbar from '@material-ui/core/Toolbar';

class ActionsBar extends Component {

    handleEnable = () => {
      const { subscriptionActivate, record } = this.props;
      subscriptionActivate(record.id, record);
    }

    handleDisable = () => {
      const { subscriptionDeactivate, record } = this.props;
      subscriptionDeactivate(record.id, record);
    }

    render() {
        const { record } = this.props;

        if (record.state_id === 1 || record.state_id ===  5) {
          // Activo
          return(
            <MuiToolbar>
              <Button variant='outlined' label='Enable' color='primary' onClick={this.handleDisable}>
                Desactivar
              </ Button>
            </MuiToolbar>
            );
        } else if (record.state_id === 3 ||  record.state_id === 6) {
          // Aguardando respuesta activación
          return(<MuiToolbar>
                   <Button variant='outlined' label='Enable' color='primary' onClick={this.handleEnable}>
                     Aprobar solicitud
                   </ Button>
                   <Button variant='outlined' label='Enable' color='secondary' onClick={this.handleDisable}>
                      Rechazar solicitud
                   </ Button>
                 </MuiToolbar>
                );
        } else if (record.state_id ===  4) {
          // Aguardando respuesta desactivación
          return(<MuiToolbar>
                  <Button variant='outlined' label='Enable' color='primary' onClick={this.handleDisable}> Aprobar solicitud </ Button>
                  <Button variant='outlined' label='Enable' color='secondary' onClick={this.handleEnable}> Rechazar solicitud </ Button>
                 </ MuiToolbar>);
        } else {
          // Inactivo
          return(<MuiToolbar>
                  <Button variant='outlined' label='Enable' color='primary' onClick={this.handleEnable}> Activar
                  </ Button>
                 </ MuiToolbar>);
        }

        return(null);
    }
}

ActionsBar.propTypes = {
    record: PropTypes.object,
    subscriptionActivate: PropTypes.func.isRequired,
    subscriptionDeactivate: PropTypes.func.isRequired
};

export default connect(null, {
    subscriptionActivate,
    subscriptionDeactivate
})(ActionsBar);
