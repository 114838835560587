import React from 'react';

import {
    List, Datagrid, TextField, Filter, DateField,
    TextInput,
} from 'react-admin';

const ErrorFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

export const ErrorList = props => (
    <List {...props} sort={{ field: 'created', order: 'DESC' }}
        bulkActionButtons={false}
        filters={<ErrorFilter />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="created" label="Creado" />
            <TextField source="account__user__username" label="Nombre de usuario" />
            <TextField source="account__user__first_name" label="Nombre" />
            <TextField source="account__user__last_name" label="Apellido" />
            <TextField source="signal" />
            <TextField source="error_message" />
        </Datagrid>
    </List>
);
