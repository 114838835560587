// in src/users.js
import React from 'react';
import { List, Edit, Datagrid, TextField, DateField, BooleanField, Filter, TextInput,
        SimpleForm, BooleanInput } from 'react-admin';
import { Toolbar, SaveButton } from 'react-admin';

const IdTypesFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

export const IdTypesList = props => (
    <List {...props}
      bulkActionButtons={false}
      filters={<IdTypesFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Id"/>
            <TextField source="name" label="Name"/>
            <BooleanField source="is_active" label="Is active"/>
            <DateField source="created" label="Created" locales="es-ES"/>
            <DateField source="last_updated" label="Updated" locales="es-ES"/>
        </Datagrid>
    </List>
);

const PostEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const IdTypesEdit = props => (
    <Edit {...props}>
      <SimpleForm toolbar={<PostEditToolbar/>}>
        <BooleanInput source="is_active" label="Is active"/>
        <TextInput source="name" label="Name"/>
      </SimpleForm>
    </Edit>
);
