import React from 'react';
import {
  Create,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm
} from 'react-admin';
import { parse } from 'query-string';


export const UserPermissionsCreate = props => {
  const { user_id: user_id_string } = parse(props.location.search);
  const user_id = user_id_string ? parseInt(user_id_string, 10) : '';

  const redirect = user_id ? `/users/${user_id}/show/permissions` : false;
  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={{ user_id: user_id }}
        redirect={redirect}
      >
        <ReferenceInput label="Account Type" source="account_type_id"
         reference="accounttypes" filter={{ is_active: true }}>
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceArrayInput label="Permissions" source="permission_id"
         reference="users_permissions" filter={{ is_active: true }}>
          <SelectArrayInput optionText="codename"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
