// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
   showNotification,
   fetchStart,
   fetchEnd,
   refreshView,
   GET_LIST
} from 'react-admin';
import { push } from 'react-router-redux';
import dataProvider from './dataProvider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Redirect } from 'react-router-dom';

class WaitingSubscriptionsButton extends Component {

    constructor(props) {
      super(props);
      this.state = { count: 0 };
    }

    getWaitingCount = () => {
        dataProvider(GET_LIST, 'subscriptions', {  pagination: { page: 1, perPage: 25 },
                                                          sort: { field: 'id', order: 'ASC' },
                                                          filter : { state__awaiting_for_admin: true } })
           .then(data => this.setState({ count: data.total }));
    };

    componentDidMount() {
      this.getWaitingCount();
      this.interval = setInterval(() => this.getWaitingCount(), 10000);
    };

    componentWillUnmount() {
      clearInterval(this.interval);
    };

    handleClick = event => {
        event.preventDefault();
        return <Redirect to='/subscriptions?filter={"awaiting_for_admin"%3Atrue%2C"state__awaiting_for_admin"%3Atrue}&order=DESC&page=1&perPage=10&sort=id' />
    };

    render() {
        if (this.state.count > 0) {
          return (
            <IconButton color="inherit" href='/admin/#/subscriptions?filter={"awaiting_for_admin"%3Atrue%2C"state__awaiting_for_admin"%3Atrue}&order=DESC&page=1&perPage=10&sort=id'>
              <Badge badgeContent={this.state.count} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          );
        }

        return (
          <IconButton color="inherit" href='/admin/#/subscriptions?filter={"awaiting_for_admin"%3Atrue%2C"state__awaiting_for_admin"%3Atrue}&order=DESC&page=1&perPage=10&sort=id'>
              <NotificationsIcon />
          </IconButton>
        );
    }
}

WaitingSubscriptionsButton.propTypes = {
    fetchStart: PropTypes.func,
    fetchEnd: PropTypes.func,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    refreshView,
    showNotification,
    fetchStart,
    fetchEnd,
    push,
})(WaitingSubscriptionsButton);
