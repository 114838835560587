// in src/users.js
import React from 'react';
import { List, Show, Edit, Create, Datagrid, TextField, EmailField, DateField, Filter,
         TextInput, SimpleForm, TabbedShowLayout, Tab, ReferenceManyField} from 'react-admin';
import AddPermissionButton from './add_permission_button';
import ListSwitch from './listSwitch';


const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="Search" source="search" alwaysOn />
    </Filter>
);

export const UserList = props => (
    <List {...props} 
      bulkActionButtons={false}
      filters={<UserFilter />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="username" label="Username"/>
            <TextField source="first_name" label="Name"/>
            <TextField source="last_name" label="SN"/>
            <EmailField source="email" label="Email" />
            <DateField source="date_joined" locales="es-ES" />
        </Datagrid>
    </List>
);

export const UserShow = props => (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Datos">
          <TextField source="id" />
          <TextField source="username" label="Username"/>
          <TextField source="first_name" label="Name"/>
          <TextField source="last_name" label="SN"/>
          <EmailField source="email" label="Email" />
          <DateField source="date_joined" locales="es-ES" />
        </Tab>
        <Tab label="Permissions" path="permisos">
          <ReferenceManyField
            addLabel={false}
            reference="users_permissions_status"
            target="user"
            sort={{ field: 'created', order: 'DESC' }}
            >
            <Datagrid>
              <DateField source="created" locales="es-ES" />
              <DateField source="last_updated" locales="es-ES" />
              <TextField source="explain" />
              <ListSwitch />
            </Datagrid>
          </ReferenceManyField>
          <AddPermissionButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
          <TextInput source="username" label="Username"/>
          <TextInput source="first_name" label="Name"/>
          <TextInput source="last_name" label="SN"/>
          <TextInput label="Password" source="password" type="password"/>
          <TextInput label="Password confirm" source="password_confirm" type="password"/>
        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" label="Username"/>
        <TextInput source="first_name" label="Name"/>
        <TextInput source="last_name" label="SN"/>
        <TextInput label="Password" source="password" type="password"/>
        <TextInput label="Password confirm" source="password_confirm" type="password"/>
      </SimpleForm>
    </Create>
);
