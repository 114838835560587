// in src/users.js
import React from 'react';
import { List, Show, Edit, Create, Datagrid, TextField, ReferenceField, EmailField, DateField, Tab,
  ChipField, Filter, TextInput, ReferenceInput, SelectInput, SimpleForm, TabbedShowLayout,
  ReferenceManyField, ReferenceArrayField, BooleanField, Responsive, EditButton, FunctionField,
  SingleFieldList, ReferenceArrayInput, SelectArrayInput, FormDataConsumer, AutocompleteInput } from 'react-admin';
  import {
      required,
      email
  } from 'react-admin';

import AddSubscriptionButton from './add_subscription_button';
import Chip from '@material-ui/core/Chip';
import rowStyle from './rowStyle';
import { SubscriptionEdit } from './SubscriptionEdit';


const AccountsFilter = props => (
    <Filter {...props}>
        <TextInput label="Buscar" source="search" alwaysOn />
        <ReferenceInput label="Tipo de Cuenta" source="account_types" reference="accounttypes" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Tipo de Documento" source="id_type" reference="idtypes" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const AccountShow = ({ permissions, ...props}) => (
    <Show {...props} filters={<AccountsFilter />}>
        <TabbedShowLayout>
          <Tab label="Datos">
            <TextField source="id" />
            <TextField source="user.username" label="Usuario"/>
            <TextField source="user.first_name" label="Nombre"/>
            <TextField source="user.last_name" label="Apellido"/>
            <ReferenceArrayField linkType={false} reference="accounttypes" source="account_types_id" label="Tipo de Cuenta">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField source="id_type_id" reference="idtypes" label="Tipo de Documento">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="id_number" label="Documento N."/>
            <EmailField source="alternative_email" label="Email alternativo"/>
            <BooleanField source="alternative_email_active" label="Email alternativo confirmado"/>
            <TextField source="phone" label="Teléfono"/>
            <DateField source="created" label="Creado" locales="es-ES" />
          </Tab>
          <Tab label="Suscripciones" path="subscriptions">
            <ReferenceManyField
              addLabel={false}
              reference="subscriptions"
              target="account"
              sort={{ field: 'created', order: 'DESC' }}
              >
              <Datagrid rowStyle={rowStyle} rowClick="expand" expand={ permissions === 'true' ? <SubscriptionEdit/> : null }>
                <DateField source="created" label="Creado" locales="es-ES" />
                <DateField source="last_updated" label="Actualizado" locales="es-ES" />
                <ReferenceField linkType={false} source="service_id" reference="services" label="Servicio">
                  <TextField source="description" />
                </ReferenceField>
                <ReferenceField linkType={false} source="state_id" reference="subscriptions_state" label="">
                  <FunctionField render={function render(record) {
                      if (record.codename === "awaiting_deactivation") {
                        return(<Chip
                                  label="Activo: Esperando desactivación"
                                  color="primary"
                                />);
                      }

                      if (record.codename === "awaiting_activation" || record.codename === "awaiting_initialization_approval") {
                        return(<Chip
                                  label="Inactivo: Esperando activación"
                                  color="error"
                                />);
                      }

                      switch(record.codename) {
                        case ("initialized"):
                          return(<Chip
                                    label="Servicio activo"
                                    color="primary"
                                  />);
                        case ("activated"):
                          return(<Chip
                                    label="Servicio activo"
                                    color="primary"
                                  />);
                        case "deactivated":
                          return(<Chip
                                    label="Servicio inactivo"
                                    color="error"
                                  />);
                        case "rejected":
                          return(<Chip
                                    label="Rechazado"
                                    color="error"
                                  />);
                        default:
                          return(<Chip
                                    label="Servicio inactivo"
                                    color="error"
                                  />);
                      }
                    }} />
                </ReferenceField>
              </Datagrid>
            </ReferenceManyField>
            { permissions === 'true' && <AddSubscriptionButton /> }
          </Tab>
        </TabbedShowLayout>
    </Show>
);

export const AccountOnlyDataShow = props => (
    <Show {...props}>
            <ReferenceArrayField linkType={false} reference="accounttypes" source="account_types_id" label="Tipo de Cuenta">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField linkType={false} source="id_type_id" reference="idtypes" label="Tipo de Documento">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="id_number" label="Documento N."/>
            <EmailField source="alternative_email" label="Email alternativo"/>
            <BooleanField source="alternative_email_active" label="Email alternativo confirmado"/>
            <TextField source="phone" label="Teléfono"/>
            <DateField source="created" label="Creado" locales="es-ES" />
    </Show>
);

export const AccountList = props => (
    <List {...props}
      bulkActionButtons={false}
      filters={<AccountsFilter />}>
      <Responsive
        small={
          <Datagrid expand={<AccountOnlyDataShow />}>
              <TextField source="id" />
              <TextField source="user.username" label="Usuario"/>
              <TextField source="user.first_name" label="Nombre"/>
              <TextField source="user.last_name" label="Apellido"/>
              <EditButton label="Editar cuenta" />
          </Datagrid>
        }
        medium= {
          <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="user.username" label="Usuario"/>
              <TextField source="user.first_name" label="Nombre"/>
              <TextField source="user.last_name" label="Apellido"/>
              <ReferenceArrayField linkType={false} reference="accounttypes" source="account_types_id" label="Tipo de Cuenta">
                <SingleFieldList>
                  <ChipField source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
              <ReferenceField source="id_type_id" reference="idtypes" label="Tipo de Documento">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="id_number" label="Documento N."/>
              <EmailField source="alternative_email" label="Email alternativo"/>
              <TextField source="phone" label="Teléfono"/>
              <DateField source="created" label="Creado" locales="es-ES" />
          </Datagrid>
        }
      />
    </List>
);

const ConditionalResponsableField = ({ record, ...rest }) =>
    record && record.dynamic_fields && record.dynamic_fields.responsable
        ? <ReferenceField basePath="/accounts" source="record.dynamic_fields.responsable" reference="accounts" label="Responsable">
          <TextField source="user.username" record={record} {...rest} />
        </ReferenceField>
        : null;

export const AccountsEdit = props => (
    <Edit {...props}>
        <SimpleForm>
          <TextInput source="user.username" label="Usuario"/>
          <ReferenceInput label="Tipo de Documento" source="id_type_id" reference="idtypes">
              <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="id_number" label="Documento N." validate={validateRequired} />
          <ReferenceArrayInput label="Tipo de cuenta" source="account_types_id" reference="accounttypes">
              <SelectArrayInput optionText="name"/>
          </ReferenceArrayInput>
          <TextInput source="user.first_name" label="Nombre"/>
          <TextInput source="user.last_name" label="Apellido"/>
          <TextInput source="alternative_email" label="Email alternativo" type="email" validate={validateEmail} />
          <TextInput source="phone" label="Teléfono"/>
          <TextInput label="Constraseña" source="password" type="password"/>
          <TextInput label="Confirmar contraseña" source="password_confirm" type="password" validate={validatePasswordConfirm} />
        </SimpleForm>
    </Edit>
);

const validateEmail = [email(), required()];
const validateRequired = required();

const validatePasswordConfirm = (value, allValues) => {
    if (!(value === allValues.password)) {
        return 'Las contraseñas ingresadas no coinciden';
    }
    return undefined;
};

export const AccountsCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="user.username" label="Usuario"/>
        <ReferenceInput label="Tipo de Documento" source="id_type_id" reference="idtypes">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="id_number" label="Documento N." validate={validateRequired}/>
        <ReferenceArrayInput label="Tipo de cuenta" source="account_types_id" reference="accounttypes">
            <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
        <TextInput source="user.first_name" label="Nombre" validate={validateRequired}/>
        <TextInput source="user.last_name" label="Apellido" validate={validateRequired}/>
        <TextInput source="alternative_email" label="Email alternativo" type="email" validate={validateEmail} />
        <TextInput source="phone" label="Teléfono"/>
        <TextInput label="Constraseña" source="password" type="password"/>
        <TextInput label="Confirmar contraseña" source="password_confirm" type="password" validate={validatePasswordConfirm} />
      </SimpleForm>
    </Create>
);
