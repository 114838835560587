// in src/comment/listSwitchActions.js
import { UPDATE } from 'react-admin';

export const SWITCH_ACTIVATE = 'SWITCH_ACTIVATE';
export const SWITCH_DEACTIVATE = 'SWITCH_DEACTIVATE';

export const switchActivate = (id, data, resource) => ({
    type: SWITCH_ACTIVATE,
    payload: { id, data: { ...data, is_active: true} },
    meta: {
      fetch: UPDATE,
      resource: resource,
      notification: {
           body: 'Acción realizada',
           level: 'info',
      },
      refresh: false,
    },
});

export const switchDeactivate = (id, data, resource) => ({
    type: SWITCH_DEACTIVATE,
    payload: { id, data: { ...data, is_active: false} },
    meta: {
      fetch: UPDATE,
      resource: resource,
      notification: {
           body: 'Acción realizada',
           level: 'info',
      },
      refresh: false,
    },
});
